import React from "react"
import { Tooltip, Collapse, Table } from "antd"
import Layout from "../../../components/headerFooterLayout"
import markdownTableNav from "../../../lib/markdownTableNav"
import {
  PerformanceIndexPageProps,
  PERFORMANCE_INDEX_COLUMNS,
} from "@richsoni/gatsby-theme-core/src/lib/"
import { SoundFilled, OrderedListOutlined } from "@ant-design/icons"

const cols = PERFORMANCE_INDEX_COLUMNS.map(column => {
  const newColumn = { ...column, dataIndex: column.key }
  switch (column.key) {
    case "hasRecordings":
      return {
        ...newColumn,
        render: (text, record, index) =>
          text ? (
            <Tooltip title="Recordings available for this performance">
              <SoundFilled />
            </Tooltip>
          ) : (
            <div />
          ),
      }

    case "setlistLength":
      return {
        ...newColumn,
        render: (text, record, index) => {
          return text ? (
            <Tooltip title="Setlist available">
              <OrderedListOutlined /> {text}
            </Tooltip>
          ) : (
            <div />
          )
        },
      }

    default:
      return newColumn
  }
})

export default ({ upcoming, past, location }: PerformanceIndexPageProps) => {
  const tableConfig = {
    style: {
      cursor: "pointer",
    },
    onRow: markdownTableNav,
    bordered: false,
    columns: cols,
    pagination: false,
    size: "small",
  }
  const hasUpcoming = !!upcoming.length

  return (
    <Layout currentPath={location.pathname}>
      <Collapse accordion defaultActiveKey={hasUpcoming ? ["1"] : ["2"]}>
        <Collapse.Panel
          key="1"
          disabled={!hasUpcoming}
          header={`Upcoming Performances (${upcoming.length})`}
        >
          <Table {...tableConfig} dataSource={upcoming} />
        </Collapse.Panel>
        <Collapse.Panel key="2" header={`Past Performances (${past.length})`}>
          <Table {...tableConfig} dataSource={past} />
        </Collapse.Panel>
      </Collapse>
    </Layout>
  )
}
