import React from "react";
import { graphql } from "gatsby"; // to query for image data
import { asStr, typeStr } from "../lib/performanceStr";
import PerformancesIndexPage from "../components/PerformancesIndexPage";
import { AllPerformanceSchema, PerformanceProps } from "../lib/";

const formatData = ({
  allPerformances,
}: AllPerformanceSchema): {
  upcoming: PerformanceProps[];
  past: PerformanceProps[];
} => {
  const performances = allPerformances.nodes.map(
    ({
      location,
      date,
      type,
      as,
      slug,
      setlist,
      hasRecordings,
    }): PerformanceProps => ({
      date,
      slug,
      hasRecordings,
      venueName: location.name,
      venueAddress: `${location.address.locality}, ${location.address.region}`,
      type: typeStr(type),
      as: asStr(as),
      key: slug,
      setlistLength: (setlist && setlist.length) || 0,
    })
  );
  return {
    upcoming: performances.filter(({ date }) => new Date(date) > new Date()),
    past: performances.filter(({ date }) => new Date(date) < new Date()),
  };
};

export const query = graphql`
  query {
    allPerformances: allPerformances(sort: { order: DESC, fields: date }) {
      nodes {
        title
        as
        type
        setlist
        date
        filename
        slug
        location {
          name
          address {
            country
            locality
            region
          }
        }
        hasRecordings
      }
    }
  }
`;

export default ({ data, location }) => (
  <PerformancesIndexPage location={location} {...formatData(data)} />
);
